import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

//highlight-start
export const pageQuery = graphql`
    query {
        allWpPost(sort: { fields: [date] }) {
            nodes {
                title
                excerpt
                slug
            }
        }
    }
`

export default function Home({ data }) {
  //highlight-line
  return (
      <Layout>
        {/* highlight-start */}
        <h1>My WordPress Blog</h1>
        <h4>pups</h4>
        {data.allWpPost.nodes.map((node) => (
            <div>
              <p>{node.title}</p>
              <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
        ))}
        {/* highlight-end */}
      </Layout>
  )
}


